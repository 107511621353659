import { Provider } from '@angular/core';
import { FilterExternalAppsWithPermissionsMethod } from 'angular-methods/filter-external-apps-with-permissions.method';
import { EUROGARD_EXTERNAL_APP_FILTER } from 'angular-services/layout/tokens';

export function provideExternalAppsFilter(): Provider[] {
  return [
    {
      provide: EUROGARD_EXTERNAL_APP_FILTER,
      useClass: FilterExternalAppsWithPermissionsMethod,
    },
  ];
}
