import { Inject, inject, Injectable } from '@angular/core';
import { AuthenticationEvent, AuthenticationEventType } from '@rxap/authentication';
import { KeycloakEventType, KeycloakService } from '@rxap/keycloak';
import { BehaviorSubject, firstValueFrom, from, Observable, switchMap } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';
import { PubSubService } from '@rxap/ngx-pub-sub';
import { ChangelogService } from 'angular-services/changelog.service';
import { HIDE_CHANGELOG } from './tokens';

@Injectable()
export class EurogardAuthenticationService {

  public readonly events$: Observable<AuthenticationEvent>;
  public readonly isAuthenticated$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  private readonly changelogService = inject(ChangelogService);

  private readonly pubsub = inject(PubSubService);

  constructor(
    private readonly keycloak: KeycloakService,
    @Inject(HIDE_CHANGELOG) private hideChangelog = false
  ) {
    this.pubsub.subscribe('authentication.logout').pipe(
      tap(() => this.signOut()),
    ).subscribe();
    this.events$ = this.keycloak.keycloakEvents$.pipe(
      filter(event => [
        KeycloakEventType.OnAuthError,
        KeycloakEventType.OnAuthLogout,
        KeycloakEventType.OnAuthSuccess,
      ].includes(event.type)),
      map(event => {

        switch (event.type) {

          case KeycloakEventType.OnAuthError:
            return {type: AuthenticationEventType.OnAuthError};

          case KeycloakEventType.OnAuthLogout:
            this.jsBridgeSignOut();
            return {type: AuthenticationEventType.OnLogout};

          case KeycloakEventType.OnAuthSuccess:
            return {type: AuthenticationEventType.OnAuthSuccess};

        }

        return {type: AuthenticationEventType.OnAuthError};

      }),
      shareReplay(),
    );
    from(this.keycloak.isReady).pipe(
      switchMap(() => this.keycloak.isLoggedIn()),
      switchMap(async isLoggedIn => {
        if (isLoggedIn) {
          return true;
        }
        await this.signIn();
        return false;
      }),
    ).subscribe(isAuthenticated => {
      if (isAuthenticated && !this.hideChangelog) {
        this.changelogService.showChangelogDialogIfNewVersion()
      }
      this.isAuthenticated$.next(isAuthenticated)
    });
  }

  public signInWithEmailAndPassword() {
    throw new Error('Not supported. currently in keycloak mode');
  }

  public async signOut() {
    if (this.hasJsBridge()) {
      console.log('use js bridge to sign out');
      this.jsBridgeSignOut();
    } else {
      await this.keycloak.logout();
    }
  }

  public signInWithProvider() {
    throw new Error('Not supported. currently in keycloak mode');
  }

  public isAuthenticated() {
    return firstValueFrom(this.isAuthenticated$.pipe(filter((isAuthenticated): isAuthenticated is boolean => isAuthenticated !==
      null)));
  }

  signIn() {
    return this.keycloak.login();
  }

  private hasJsBridge() {
    return (window as any)['jsBridge'] !== undefined;
  }

  private jsBridgeSignOut() {
    const jsBridge = (window as any)['jsBridge'];
    if (jsBridge) {
      jsBridge.signOut();
    } else {
      console.error('js bridge is not present');
    }
  }

}
