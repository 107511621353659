import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { UserControllerGetApiKeyResponse } from '../responses/user-controller-get-api-key.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'user-controller-getApiKey',
    operation: '{"operationId":"user-controller-getApiKey","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","properties":{"error":{"type":"string"},"api_key":{"type":"string"},"api_key_expires_on":{"type":"string"}}}}}}},"method":"get","path":"/user-controller/apikey"}'
  })
export class UserControllerGetApiKeyRemoteMethod extends OpenApiRemoteMethod<UserControllerGetApiKeyResponse, void, void> {
  public override call(): Promise<UserControllerGetApiKeyResponse> {
    return super.call();
  }
}
