import { BaseWidget, WidgetRef } from './base.widget';
import { ChartOptions } from 'chart.js';

export enum ChartType {
  FILL_NULL = 'fill(null)',
  FILL_NONE = 'fill(none)',
  FILL_0 = 'fill(0)'
}

export interface ChartWidget extends BaseWidget<WidgetRef[]> {
  options: ChartOptions;
  refresh: number | null;
  interval: number;
  condition: string;
  type?: ChartType;
}
