import { ComponentType } from "@angular/cdk/portal";
import { InjectionToken } from "@angular/core";
import { LogoConfig } from "@rxap/config";
import { NavigationWithInserts } from 'angular-components/navigation/navigation-item';
import { ExternalApp, ExtractUsernameFromProfileFn, ReleaseInfoModule, SettingsMenuItem, SettingsMenuItemComponent } from "./types";
import { DataSource, MethodWithParameters } from "@rxap/pattern";
import { staticDataSource } from "@rxap/data-source";

export const EUROGARD_LOGO_CONFIG = new InjectionToken<LogoConfig>('eurogard/components/logo-config');
export const EUROGARD_FOOTER_COMPONENT = new InjectionToken<ComponentType<unknown>>('eurogard/components/footer-component');
export const EUROGARD_HEADER_COMPONENT = new InjectionToken<ComponentType<unknown>>('eurogard/components/header-component');
export const EUROGARD_NAVIGATION_CONFIG = new InjectionToken<NavigationWithInserts | (() => NavigationWithInserts)>(
  'eurogard/components/navigation-config');
export const EUROGARD_NAVIGATION_CONFIG_INSERTS = new InjectionToken<Record<string, NavigationWithInserts>>(
  'eurogard/components/navigation-config-inserts');
export const EUROGARD_EXTERNAL_APP = new InjectionToken<ExternalApp>('eurogard/components/external-app');

export const EUROGARD_RELEASE_INFO_MODULE = new InjectionToken<ReleaseInfoModule>('eurogard/components/release-info-module');

export const EUROGARD_SETTINGS_MENU_ITEM_COMPONENT = new InjectionToken<SettingsMenuItemComponent>('eurogard/components/settings-menu-item-component');

export const EUROGARD_SETTINGS_MENU_ITEM = new InjectionToken<SettingsMenuItem>('eurogard/components/settings-menu-item');

export const EUROGARD_USER_PROFILE_DATA_SOURCE = new InjectionToken<DataSource>('eurogard/components/user-profile-data-source', {
  providedIn: 'root',
  factory: () => staticDataSource(null),
});

export const EUROGARD_EXTERNAL_APP_FILTER = new InjectionToken<MethodWithParameters<ExternalApp[], ExternalApp[]>>('eurogard/components/app-config-filter');

export const EXTRACT_USERNAME_FROM_PROFILE = new InjectionToken<ExtractUsernameFromProfileFn>(
  'extract-username-from-profile',
  {
    providedIn: 'root',
    factory: () => (profile: any) => (
      profile ? profile.username ?? profile.email ?? profile.name : null
    ) ?? null,
  },
);
