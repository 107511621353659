import { inject } from '@angular/core';
import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '@rxap/config';

// TODO : check if the cors interceptor is still needed
export function CorsInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const config  = inject(ConfigService);
  const baseUrl = config.get('api.legacy.baseUrl');
  if (baseUrl && request.url.includes(baseUrl)) {
    return next(request.clone({
      withCredentials: true,
    }));
  }
  return next(request);
}
