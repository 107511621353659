import { isDevMode } from '@angular/core';
import { KeycloakService } from '@rxap/keycloak';
import { ConfigService } from '@rxap/config';

function showError(message: string) {
  const hasUl              = document.getElementById('keycloak-error') !== null;
  const ul                 = document.getElementById('keycloak-error') ?? document.createElement('ul');
  ul.id                    = 'keycloak-error';
  ul.style.position        = 'fixed';
  ul.style.bottom          = '16px';
  ul.style.right           = '16px';
  ul.style.backgroundColor = 'white';
  ul.style.padding         = '32px';
  ul.style.zIndex          = '99999999';
  ul.style.color           = 'black';
  const li                 = document.createElement('li');
  li.innerText             = message;
  ul.appendChild(li);
  if (!hasUl) {
    document.body.appendChild(ul);
  }
}

export function InitializeKeycloak(keycloak: KeycloakService, configService: ConfigService) {

  const langMatch = location.pathname.match(/^\/(\w{2})\//);
  const localId   = (langMatch ? langMatch[1] + '/' : '');

  const initOptions: Keycloak.KeycloakInitOptions = {
    checkLoginIframe: true,
    checkLoginIframeInterval: 10,
    redirectUri: window.location.origin + '/' + localId,
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/' + localId + 'silent-check-sso.html',
  };

  let search                                     = location.search;
  const data: Record<string, string | undefined> = {
    refreshToken: localStorage.getItem('__refreshToken__') ?? undefined,
    accessToken: localStorage.getItem('__accessToken__') ?? undefined,
    idToken: localStorage.getItem('__idToken__') ?? undefined,
  };

  if (search && search.length) {
    search         = search.replace(/^\?/, '');
    const segments = search.split('&');
    for (const segment of segments) {
      const keyValue    = segment.split('=');
      data[keyValue[0]] = keyValue[1];
    }
  }

  if (isDevMode()) {
    console.debug('search keys: ' + Object.keys(data));
  }

  for (const [ key, value ] of Object.entries(data)) {
    if (value) {
      switch (key) {
        case 'refreshToken':
          initOptions.refreshToken = value;
          break;
        case 'accessToken':
          initOptions.token = value;
          break;
        case 'idToken':
          initOptions.idToken = value;
          break;
      }
    }
  }

  if (isDevMode()) {
    console.debug('initOptions', initOptions);
  }

  return () => {
    const config = {
      url: configService.getOrThrow('keycloak.url'),
      realm: configService.getOrThrow('keycloak.realm'),
      clientId: configService.getOrThrow('keycloak.clientId'),
    };
    return keycloak.init({
      config,
      loadUserProfileAtStartUp: true,
      initOptions,
      bearerExcludedUrls: [ '/' + localId + 'assets' ],
    })
    .then(() => localStorage.removeItem('__auth_counter__'))
    .catch((e: any) => {
      console.error('Keycloak initialization failed', e);
      if (typeof e === 'object' && e.error === 'login_required') {
        if (!localStorage.getItem('__auth_counter__')) {
          localStorage.setItem('__auth_counter__', 'true');
          location.reload();
          return;
        }
      }
      const message = `Keycloak initialization failed. Contact the administrator:\n${ JSON.stringify(config) }`;
      showError(message);
      throw new Error(message);
    });
  };
}
