import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { ChangelogLastVersionControllerSetRequestBody } from '../request-bodies/changelog-last-version-controller-set.request-body';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'service-user-settings',
    operationId: 'ChangelogLastVersionController_set',
    operation: '{"operationId":"ChangelogLastVersionController_set","parameters":[],"requestBody":{"required":true,"content":{"application/json":{"schema":{"type":"object","properties":{"changelogLastVersion":{"type":"string"}}}}}},"responses":{"201":{}},"method":"post","path":"/user/changelog-last-version"}'
  })
export class ChangelogLastVersionControllerSetRemoteMethod extends OpenApiRemoteMethod<void, void, ChangelogLastVersionControllerSetRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<void, ChangelogLastVersionControllerSetRequestBody>): Promise<void> {
    return super.call(parameters);
  }
}
