import { APP_INITIALIZER, Provider } from '@angular/core';
import { SetUserLanguageInCloudServerMethod } from 'angular-methods/set-user-language-in-cloud-server.method';
import { UserSettingsLanguageService } from 'angular-services/user-area/user-settings-language.service';

export function ProvideSetLanguageHook(): Provider {
  return {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [ UserSettingsLanguageService, SetUserLanguageInCloudServerMethod ],
    useFactory: (
      userSettingsLanguageService: UserSettingsLanguageService,
      setUserLanguageInCloudServerMethod: SetUserLanguageInCloudServerMethod,
    ) => () => {
      userSettingsLanguageService.registerSetLanguageHook((language: string, unauthenticated: boolean) => {
        if (unauthenticated) {
          console.log('unauthenticated');
        } else {
          return setUserLanguageInCloudServerMethod.call(language);
        }
        return Promise.resolve();
      });
    },
  };
}
