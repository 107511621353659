import { inject, Injectable } from '@angular/core';
import { Method } from '@rxap/pattern';
import {
  UserControllerUpdateUserLanguageRemoteMethod,
} from 'open-api-legacy/remote-methods/user-controller-update-user-language.remote-method';

@Injectable({providedIn: 'root'})
export class SetUserLanguageInCloudServerMethod implements Method<any, string> {

  private setLanguage = inject(UserControllerUpdateUserLanguageRemoteMethod);

  async call(language: string): Promise<void> {
    language = language.replace(/-.+$/, '').toLowerCase();
    let code: string;
    switch (language) {

      case 'de':
        code = '0';
        break;

      case 'en':
        code = '1';
        break;

      case 'fr':
        code = '2';
        break;

      case 'it':
        code = '3';
        break;

      default:
        throw new Error(`The language '${ language } is not supported'`);

    }

    await this.setLanguage.call({
      requestBody: {
        updateMap: {
          userLanguage: code,
        },
      },
    });
  }

}
