import { Injectable, inject, NgZone } from '@angular/core';
import { UserControllerMeRemoteMethod } from 'open-api-legacy/remote-methods/user-controller-me.remote-method';

@Injectable({
  providedIn: 'root',
})
export class LanguageRedirectService {
  private readonly getUserProfile = inject(UserControllerMeRemoteMethod);
  private readonly ngZone = inject(NgZone);

  redirectLanguageIfNeeded() {
    const language = this.getLanguageFromUrl(window.location.href);
    console.log('Current language:', language);

    this.getUserProfile.call().then(user => {
      let userLocale = '';
      switch (user.userLanguage) {
        case 0:
          userLocale = 'de';
          break;
        case 1:
          userLocale = 'en';
          break;
        case 2:
          userLocale = 'fr';
          break;
        case 3:
          userLocale = 'it';
          break;
      }
      if (language !== null && userLocale !== '' && userLocale !== language) {
        this.ngZone.run(() => {
          const newLocaleUrl = window.location.href.replace(`/${language}`, `/${userLocale}`);
          window.location.replace(newLocaleUrl);
        });
      }
    });
  }

  private getLanguageFromUrl(url: string) {
    const match = url.match(/\/(en|de|fr|it)\//);
    return match ? match[1] : null;
  }
}
