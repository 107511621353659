import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class CompanySelectService {
  public companyId: string | null = null;

  constructor(public router: Router) {
    this.companyId = localStorage.getItem('eurogard_company_id') ?? null;
  }

  public selectCompany(id: string | null): void {
    if (id && typeof id === 'string' && this.companyId !== id) {
      this.companyId = id;
      localStorage.setItem('eurogard_company_id', this.companyId);
      this.router.navigate([ this.router.url ]).then(() => {
        window.location.reload();
      });
    } else {
      this.companyId = null;
      localStorage.removeItem('eurogard_company_id');
      this.router.navigate([ this.router.url ]).then(() => {
        window.location.reload();
      });
    }
  }
}
