import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { StatusControllerVersionResponse } from '../responses/status-controller-version.response';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'legacy',
    operationId: 'status-controller-version',
    operation: '{"operationId":"status-controller-version","responses":{"200":{"content":{"application/json":{"schema":{"type":"object","additionalProperties":{"type":"string"}}}}}},"method":"get","path":"/status-controller/version"}'
  })
export class StatusControllerVersionRemoteMethod extends OpenApiRemoteMethod<StatusControllerVersionResponse, void, void> {
  public override call(): Promise<StatusControllerVersionResponse> {
    return super.call();
  }
}
