import { Injectable } from '@angular/core';
import { OpenApiRemoteMethod, OpenApiRemoteMethodParameter, RxapOpenApiRemoteMethod } from '@rxap/open-api/remote-method';
import { LanguageControllerSetRequestBody } from '../request-bodies/language-controller-set.request-body';

@Injectable({
    providedIn: 'root'
  })
@RxapOpenApiRemoteMethod({
    serverId: 'service-user-settings',
    operationId: 'LanguageController_set',
    operation: '{"operationId":"LanguageController_set","parameters":[],"requestBody":{"required":true,"content":{"application/json":{"schema":{"type":"object","properties":{"language":{"type":"string"}},"required":["language"]}}}},"responses":{"201":{}},"method":"post","path":"/user/language"}'
  })
export class LanguageControllerSetRemoteMethod extends OpenApiRemoteMethod<void, void, LanguageControllerSetRequestBody> {
  public override call(parameters: OpenApiRemoteMethodParameter<void, LanguageControllerSetRequestBody>): Promise<void> {
    return super.call(parameters);
  }
}
