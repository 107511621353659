export function transformDocumentToUpdateMap(document: any): Record<string, string> {
  return Object.entries(document)
  .filter(([ key, _ ]) => key !== 'uuid')
  .map(([ key, value ]) => {
    if (typeof value === 'string') {
      return [ key, value ];
    } else if (value === null) {
      return [ key, value ];
    } else {
      return [ key, JSON.stringify(value) ];
    }
  })
  .reduce((updateMap: any, [ key, value ]: any[]) => ({...updateMap, [key]: value}), {});
}
