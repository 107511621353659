import { Provider } from '@angular/core';
import { ConfigService } from '@rxap/config';
import { WrappedSocket } from './socket-io.service';
import { EUROGARD_SOCKET_IO_OPTIONS, EUROGARD_SOCKET_IO_URL } from './tokens';

export function provideSocketIoFromConfig(urlKey = 'socket.url', optionsKey = 'socket.options'): Provider[] {
  return [
    {
      provide: EUROGARD_SOCKET_IO_URL,
      useFactory: (config: ConfigService) => config.getOrThrow(urlKey),
      deps: [ ConfigService ]
    },
    {
      provide: EUROGARD_SOCKET_IO_OPTIONS,
      useFactory: (config: ConfigService) => config.get(optionsKey, {}),
      deps: [ ConfigService ]
    },
    WrappedSocket
  ];
}
