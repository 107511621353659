import { inject, Injectable, OnDestroy } from '@angular/core';
import {
  UserControllerGetApiKeyRemoteMethod,
} from 'open-api-legacy/remote-methods/user-controller-get-api-key.remote-method';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { RxapAuthenticationService } from '@rxap/authentication';
import { SERVICE_SERVER_API_KEY } from './tokens';

@Injectable({providedIn: 'root'})
export class ServiceServerApiKeyService implements OnDestroy {

  private readonly apiKey                    = inject(SERVICE_SERVER_API_KEY);
  private readonly authService               = inject(RxapAuthenticationService);
  private readonly getApiKey                 = inject(UserControllerGetApiKeyRemoteMethod);
  private _subscription: Subscription | null = null;

  start() {
    if (this._subscription) {
      return;
    }
    this._subscription = this.authService.isAuthenticated$.pipe(
      filter(Boolean),
      // TODO : test what happens if the requires fails. Can the start method be called again?
      switchMap(() => this.getApiKey.call()),
      map(response => response?.api_key ?? null),
      filter(Boolean),
      tap({
        next: apiKey => this.apiKey.next(apiKey),
        error: () => this.apiKey.next(null),
        complete: () => this._subscription = null,
      }),
    ).subscribe()
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

}
