import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { RxapAuthenticationService } from '@rxap/authentication';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KeycloakService } from '@rxap/keycloak';
import { EurogardAuthenticationService } from '../eurogard-authentication.service';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'eurogard-insufficient-permissions',
  templateUrl: './insufficient-permissions.component.html',
  styleUrls: [ './insufficient-permissions.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,

  standalone: true,
  imports: [
    MatCardModule,
    NgFor,
    MatButtonModule,
    RouterLink,
    AsyncPipe,
  ],
})
export class InsufficientPermissionsComponent implements OnInit {

  public requiredPermissions$: Observable<string[]>;

  constructor(
    private readonly route: ActivatedRoute,
    @Inject(KeycloakService)
    private readonly keycloak: KeycloakService,
    @Inject(RxapAuthenticationService)
    private readonly authenticationService: EurogardAuthenticationService,
  ) {
    this.requiredPermissions$ = this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.get('permissions') ?? '[]'),
      map(permissionsString => JSON.parse(permissionsString)),
    );
  }

  public ngOnInit() {
    this.keycloak.isLoggedIn().then(isLoggedIn => {
      if (!isLoggedIn) {
        console.warn('logout: user does not have required permissions and is not log in');
        this.logout();
      }
    });
  }

  public async logout() {
    await this.authenticationService.signOut();
  }

}
