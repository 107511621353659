import { APP_INITIALIZER, StaticProvider } from '@angular/core';
import { RxapAuthenticationService } from '@rxap/authentication';
import { EurogardAuthenticationService } from './eurogard-authentication.service';
import { EurogardAuthorizationService } from './eurogard-authorization.service';
import { InitializeKeycloak } from './initialize-keycloak';
import { KeycloakService } from '@rxap/keycloak';
import { ConfigService } from '@rxap/config';
import { AuthorizationService } from '@rxap/authorization';
import { HIDE_CHANGELOG } from './tokens';


export function ProvideAuth(hideChangelog = false): StaticProvider[] {
  return [
    {
      provide: AuthorizationService,
      useClass: EurogardAuthorizationService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeKeycloak,
      multi: true,
      deps: [ KeycloakService, ConfigService ],
    },
    {
      provide: RxapAuthenticationService,
      useClass: EurogardAuthenticationService,
    },
    {
      provide: HIDE_CHANGELOG,
      useValue: hideChangelog
    }
  ];
}
