import { StaticProvider } from '@angular/core';
import { ProvideIconAssetPath as RxapProvideIconAssetPath } from '@rxap/icon';

export function ProvideIconAssetPath(): StaticProvider {
  return RxapProvideIconAssetPath([
    'mdi.svg',
    'custom.svg',
    'feather.svg',
  ]);
}
