import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ConfigService } from '@rxap/config';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { isDefined } from '@rxap/rxjs';
import { KeycloakService } from '@rxap/keycloak';
import { SERVICE_SERVER_API_KEY } from './tokens';
import { ServiceServerApiKeyService } from './service-server-api-key.service';

declare let $localize: any;

let open = false;

let once = false;

async function refreshApiToken(auth: KeycloakService) {
  if (open || once) {
    return;
  }
  once = true;
  open           = true;
  const response = confirm($localize`Your API key has expired. Do you want to request a new one?`);
  open           = false;
  if (response) {
    await auth.logout();
  }
}

export function ServiceServerApiKeyHttpInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {

  const apiKey        = inject(SERVICE_SERVER_API_KEY);
  const config        = inject(ConfigService);
  const auth          = inject(KeycloakService);
  inject(ServiceServerApiKeyService).start();

  if (
    req.url.match(new RegExp(`^${ config.get('api.serviceServerBaseUrl') }`)) ||
    req.url.match(new RegExp(`^${location.origin}${config.get('api.service-server.baseUrl') }`)) ||
    req.url.match(/\/api\/feature\/remote-connection/) ||
    req.url.match(/\/api\/app\/service-server/)
  ) {
    return apiKey.pipe(
      isDefined(),
      take(1),
      switchMap(apiKey => next(req.clone({
        setHeaders: {
          'x-api-key': apiKey,
        },
        withCredentials: true,
      }))),
      tap({
        error: error => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              const message = error.error.message;
              if (message === 'Invalid API key!' || message === 'Unauthorized') {
                apiKey.next(null);
                refreshApiToken(auth);
              }
            }
          }
        },
      }),
    );
  }
  return next(req);

}
