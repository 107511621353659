import { StaticProvider } from '@angular/core';
import { DefaultErrorCodeExtractor, RXAP_ERROR_INTERCEPTOR_OPTIONS } from '@rxap/ngx-error';
import { HttpErrorResponse } from '@angular/common/http';

export function ProvideErrorInterceptorOptions(): StaticProvider {
  return {
    provide: RXAP_ERROR_INTERCEPTOR_OPTIONS,
    useValue: {
      extractErrorCode: (error: HttpErrorResponse) => {
        let code = DefaultErrorCodeExtractor(error);

        const message = error.error?.message ?? error.message ?? '';
        const match   = message?.match(/e_(\d+)#/);
        if (match) {
          code = match[1];
          if (error.error?.message) {
            error.error.message = error.error.message.replace(/e_\d+#/, '');
          } else {
            Reflect.set(error, 'message', error.message.replace(/e_\d+#/, ''));
          }
        }

        return code;
      },
    },
  };
}
