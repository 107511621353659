import { Environment } from '@rxap/environment';

export const environment: Environment = {
  name: 'production',
  production: true,
  app: 'portal',
  serviceWorker: true,
  sentry: {
    dsn: 'https://28dda2634d7e445689ed1f27aadbdb77@sentry.eurogard.cloud/6',
    enabled: true,
    debug: false,
  },
  firebase: {
    messaging: {
      vapidKey: 'BMaq9J2wJDITj-sz_z-yqQ4_vV_IMasN4RDuvNmn1dRcn46Tddb4t75VarV2CRK57dBkvQtyfOoTmIPdvRaTG10',
    },
    options: {
      apiKey: 'AIzaSyCaaBujshxKsZ_vGNcds6X_FjxuSeg-5MY',
      projectId: 'eurogard-cloud-app',
      messagingSenderId: '938611462624',
      appId: '1:938611462624:web:e026037d800080d3531b85',
    },
  },
};
