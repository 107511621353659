import { importProvidersFrom, LOCALE_ID, Optional } from '@angular/core';
import { provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { de, enUS, fr, it } from 'date-fns/locale';
import { provideDateFnsDatetimeAdapter } from '@ng-matero/extensions-date-fns-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';

export function ProvideDate(map: Record<string, any> = {
  'en-US': enUS,
  'fr-FR': fr,
  'de-DE': de,
  'it-IT': it,
  'en': enUS,
  'fr': fr,
  'de': de,
  'it': it,
}) {
  return [
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (localeId: string | null) => (localeId ? map[localeId] : null) ?? enUS,
      deps: [ [ new Optional(), LOCALE_ID ] ],
    },
    provideDateFnsDatetimeAdapter(),
    provideDateFnsAdapter(),
    importProvidersFrom(
      NgxMatNativeDateModule,
    ),
  ];
}
