import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { RxapAuthenticationService } from '@rxap/authentication';
import { OpenApiConfigService } from '@rxap/open-api';
import { Observable } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { EurogardAuthenticationService } from './eurogard-authentication.service';


export function AuthHttpInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const openApiService        = inject(OpenApiConfigService);
  const authenticationService = inject<unknown>(RxapAuthenticationService) as EurogardAuthenticationService;
  if (req.url.includes(openApiService.getBaseUrl())) {
    return authenticationService.isAuthenticated$.pipe(
      filter(isAuthenticated => isAuthenticated !== null),
      switchMap(() => next(req).pipe(
        tap({
          error: error => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 401) {
                authenticationService.signIn();
              }
            }
          },
        }),
      )),
    );
  }
  return next(req);
}
