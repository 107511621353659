import { APP_INITIALIZER, Injectable, OnDestroy, Provider } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UserControllerMeRemoteMethod } from 'open-api-legacy/remote-methods/user-controller-me.remote-method';
import { isDefined } from '@rxap/rxjs';
import {
  UserControllerFirebaseWebAppTokenRemoteMethod,
} from 'open-api-legacy/remote-methods/user-controller-firebase-web-app-token.remote-method';
import { AngularFireMessaging, VAPID_KEY } from '@angular/fire/compat/messaging';
import { RxapAuthenticationService } from '@rxap/authentication';

@Injectable({providedIn: 'root'})
export class FirebaseMessageService implements OnDestroy {

  private _subscription?: Subscription;

  constructor(
    private readonly getProfile: UserControllerMeRemoteMethod,
    private afMessaging: AngularFireMessaging,
    private readonly updateToken: UserControllerFirebaseWebAppTokenRemoteMethod,
    private readonly auth: RxapAuthenticationService,
  ) {
  }

  public start() {
    this.afMessaging.messages
    .pipe(
      tap((message: any) =>
        this.showNotification(
          message.notification.title,
          message.notification.body,
        ),
      ),
    )
    .subscribe();
    this.auth.isAuthenticated$.pipe(
      filter((isAuthenticated) => isAuthenticated === true),
      tap(() => {
        this.requestPermission();
        this.requestToken();
      }),
    ).subscribe();
  }

  public ngOnDestroy() {
    this._subscription?.unsubscribe();
  }

  public requestPermission() {
    console.debug('request notification permission');
    this.afMessaging.requestPermission
    .subscribe({
        next: () => {
          console.info('cloud messaging permission is granted');
        },
        error: () => {
          console.error('cloud messaging permission is denied');
        },
      },
    );
  }

  public requestToken() {
    console.debug('request firebase message token');
    this._subscription?.unsubscribe();
    this._subscription = this.afMessaging.requestToken
    .pipe(
      isDefined(),
      tap(token => this.setTokenInProfile(token)),
    )
    .subscribe();
  }

  private async setTokenInProfile(token: string) {
    console.debug('firebase message token received');
    const profile = await this.getProfile.call();
    if (profile) {
      const profileUuid = profile.uuid;

      if (!profileUuid || typeof profileUuid !== 'string') {
        console.error('Failed to set the firebase message token, bc the profile id is not valid');
        return;
      }

      await this.updateToken.call({
        parameters: {uuid: profileUuid},
        requestBody: {
          updateMap: {
            firebaseWebAppMessageToken: token,
          },
        },
      });

      console.log('firebase message token is set');
    } else {
      console.error('Failed to set the firebase message token, bc could not load the profile');
    }
  }

  public showNotification(title: string, body: string): void {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      alert('This browser does not support desktop notification');
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      new Notification(title, {body});
    } else {
      console.warn('Notification permission is not granted');
    }
  }

}

export function ProvideFirebaseMessages(...providers: Provider[]): Provider[] {
  return [
    {
      provide: APP_INITIALIZER,
      useFactory: (service: FirebaseMessageService) => () => service.start(),
      deps: [ FirebaseMessageService ],
      multi: true,
    },
    ...providers,
  ];
}

export function withVapidKey(vapidKey: string): Provider {
  return {
    provide: VAPID_KEY,
    useValue: vapidKey,
  };
}
