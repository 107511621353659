import { IconConfig } from '@rxap/utilities';
import { ThemePalette } from '@angular/material/core';

export interface WidgetRef {
  /**
   * The uuid of a data definition object.
   *
   * The data definition uuid is unique in the context of the parent
   * BaseWidget object
   */
  dataDefinition: string;
  /**
   * The uuid of a machine object
   */
  machine: string;
  /**
   * A color used for the data point
   */
  color?: string;
}

export enum WidgetFontSize {
  SMALLER = 'smaller',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  LARGER = 'larger'
}

export interface BaseWidgetIcon {
  /**
   * @deprecated instead the property bgColor should be used
   */
  color?: ThemePalette;
  bgColor?: string;
}

export type WidgetIcon = BaseWidgetIcon & IconConfig;

/**
 * The base interface for any Widget content
 */
export interface BaseWidget<DD extends WidgetRef | WidgetRef[] = WidgetRef> {
  /**
   * A object or collection of WidgetRef objects.
   *
   * Used to reference to a specified data definition object or a collection
   * of unique data definition objects
   */
  ref?: DD;
  fontSize?: WidgetFontSize;
  icon?: WidgetIcon;
}
