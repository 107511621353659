import { InjectionToken } from '@angular/core';
import { SocketIoConfig } from './socket-io.config';

export const EUROGARD_SOCKET_IO_URL = new InjectionToken<SocketIoConfig>(
  'eurogard-socket-io/url',
);

export const EUROGARD_SOCKET_IO_OPTIONS = new InjectionToken<SocketIoConfig>(
  'eurogard-socket-io/options',
);
